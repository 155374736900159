<script setup lang="ts">
    import { useI18n } from 'vue-i18n';

    defineProps<{
        translationKey: string;
    }>();

    const { t } = useI18n();
</script>

<template>
    <div class="seo-text-display-container">
        <h1 class="text-5xl-bold-line-height-auto">{{ t(`SEO_TEXT_DISPLAY.${translationKey}.TITLE`) }}</h1>
        <p class="text-base-regular-line-height-auto">
            {{ t(`SEO_TEXT_DISPLAY.${translationKey}.TEXT`) }}
        </p>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .seo-text-display-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 24px;

        p {
            margin: 0;
        }
    }
</style>
