<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import Button from 'primevue/button';

    const emits = defineEmits<{
        'click-cta-button': [];
    }>();

    const { t } = useI18n();
</script>

<template>
    <div class="no-products-found-container">
        <div class="no-products-found-content">
            <h3>{{ t('PRODUCT.NO_PRODUCTS_FOUND.TITLE') }}</h3>
            <div>
                {{ t('PRODUCT.NO_PRODUCTS_FOUND.DESCRIPTION') }}
            </div>
            <Button
                icon="pi pi-percentage"
                :label="t('PRODUCT.NO_PRODUCTS_FOUND.CTA_BUTTON')"
                @click="emits('click-cta-button')"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .no-products-found-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: main.$spacing-7;
    }

    .no-products-found-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 588px;
        text-align: center;
        gap: main.$spacing-5;
    }
</style>
