<script setup lang="ts">
    import { computed, watch } from 'vue';
    import { RouterLink } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import Button from 'primevue/button';
    import type { CtxProduct } from '@containex/portal-backend-api-client';
    import { calculateRentalPrice, VIRTUAL_RENTAL_DEPOT } from '@containex/portal-business-logic';
    import { Availability, DepotType, type MarketDto } from '@containex/portal-backend-dto';
    import { isDefined } from '@containex/common-utils';
    import { buttonFontWeight500 } from '@/styling/components/button';
    import { Navigation } from '@/router/navigation';
    import ChippedContent from '@/components/ChippedContent.vue';
    import ProductImage from '@/components/ProductImage.vue';
    import DepotLocationWithIcon from '@/components/DepotLocationWithIcon.vue';
    import ProductAvailability from '@/components/ProductAvailability.vue';
    import PriceInformation from '@/components/PriceInformation.vue';
    import ProductStockQuantityDisplay from '@/components/ProductStockQuantityDisplay.vue';
    import { useCartAction, useCartQuery } from '@/composables/cart';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { optimalVariantForMarket } from '@/product/common/util/optimal-variant-for-market';
    import ProductCartButtonGroup from '@/product/common/components/ProductCartButtonGroup.vue';
    import { filterProductVariantForProvisionType } from '@/util/filterProductVariantForProvisionType';
    import { getAvailabilityForVariantBasedOnCart } from '@/util/variantAvailability';
    import { isDiscountedVariant } from '@/util/variant-discount';
    import { isProductUsed } from '@/util/isProductUsed';

    const props = defineProps<{
        product: CtxProduct;
        currentRegionId?: string;
        postalCode?: string;
        isRental: boolean;
        isRentalDurationSet: boolean;
        market: MarketDto;
        isSearchResult: boolean;
    }>();

    const emits = defineEmits<{
        addToCart: [product: CtxProduct, variantIdToAdd: string];
        showPrice: [];
    }>();

    const { t } = useI18n();
    const { formatPrice } = usePriceFormatter();
    const { cart, rentalStart, rentalEnd } = useCartQuery();
    const cartAction = useCartAction();

    const currentVariant = computed(() => {
        if (props.isRental) {
            // fix for CPP-806
            // there are no prices set for rental products if there's no zip code given, therefore optimalVariantForMarket does not find a variant.
            // due to data quality (prices for regions) there might not be a price despite a zip code either, therefore we handle rental products different under the premise of rental products only having one variant
            const filteredVariants = props.product.variants.filter(
                (variant) =>
                    filterProductVariantForProvisionType(variant, props.market.code, props.isRental) &&
                    variant.depotQuantity.depot.depot_id === VIRTUAL_RENTAL_DEPOT
            );
            return filteredVariants[0];
        }
        return optimalVariantForMarket(props.product.variants, props.market.code, props.isRental);
    });

    const productLink = computed(() => {
        const name = props.isRental ? Navigation.RentalProductDetail : Navigation.SalesProductDetail;

        return {
            name,
            params: {
                id: props.product.id,
                variantId: currentVariant.value?.id,
            },
        };
    });

    const minProductPrice = computed(() => {
        if (currentVariant.value?.calculated_price != null) {
            return currentVariant.value.calculated_price;
        }

        const filteredPrices = (props.product.variants ?? [])
            .map((variant) => variant.calculated_price)
            .filter(isDefined);

        return Math.min(...filteredPrices);
    });

    const minOriginalProductPrice = computed(() => {
        if (currentVariant.value?.original_price != null) {
            return currentVariant.value.original_price;
        }

        const filteredPrices = (props.product.variants ?? [])
            .map((variant) => variant.original_price)
            .filter(isDefined);

        return Math.min(...filteredPrices);
    });

    const variantIfNothingIsAvailable = computed(() =>
        props.product.variants.find((i) => i.calculated_price === minProductPrice.value)
    );

    const currentDepotType = computed(() => {
        if (
            currentVariant.value?.depotQuantity.depot.type != null ||
            variantIfNothingIsAvailable.value?.depotQuantity.depot.type != null
        ) {
            return (
                currentVariant.value?.depotQuantity.depot.type ??
                variantIfNothingIsAvailable.value?.depotQuantity.depot.type
            );
        }
        return null;
    });

    const currentDepotLocation = computed(() => {
        if (
            currentVariant.value?.depotQuantity.depot.address.city != null ||
            variantIfNothingIsAvailable.value?.depotQuantity.depot.address.city != null
        ) {
            return (
                currentVariant.value?.depotQuantity.depot.address.city ??
                variantIfNothingIsAvailable.value?.depotQuantity.depot.address.city
            );
        }
        return null;
    });

    const currentAvailability = computed(() => {
        if (currentVariant.value != null) {
            return getAvailabilityForVariantBasedOnCart(currentVariant.value, cart.value, true);
        }
        return Availability.NotAvailable;
    });

    const currentVariantCompletionDate = computed(() => currentVariant.value?.depotQuantity.completion_date);
    const currentVariantOnDemandCompletionDate = computed(
        () => currentVariant.value?.depotQuantity.on_demand_completion_date
    );

    const formattedProductPrice = computed(() => {
        if (!props.isRental) {
            return formatPrice(minProductPrice.value);
        }

        const price = calculateRentalPrice(
            minProductPrice.value,
            props.isRentalDurationSet,
            rentalStart.value,
            rentalEnd.value
        );
        return formatPrice(price);
    });

    const isCurrentVariantDiscounted = computed(() => {
        if (currentVariant.value == null) {
            return false;
        }

        return isDiscountedVariant(
            currentVariant.value,
            props.isRental,
            props.isRentalDurationSet,
            rentalStart.value,
            rentalEnd.value
        );
    });

    const formattedOriginalProductPrice = computed(() => formatPrice(minOriginalProductPrice.value));

    const additionalDepotVariants = computed(() => {
        const variants = props.product.variants ?? [];
        const variantsForMarket = variants.filter((variant) => {
            return filterProductVariantForProvisionType(variant, props.market.code, props.isRental);
        });
        return (
            variantsForMarket
                .map((variant) => getAvailabilityForVariantBasedOnCart(variant, cart.value, true))
                .filter((variantAvailability) => variantAvailability !== Availability.NotAvailable).length - 1
        );
    });

    let isVariantAvailableForCart = computed(() => cartAction.isVariantAddableToCart(currentVariant.value));

    watch(cart, () => {
        isVariantAvailableForCart = computed(() => cartAction.isVariantAddableToCart(currentVariant.value));
    });
</script>

<template>
    <RouterLink class="link-reset grid-item" :to="productLink">
        <div class="product" :class="{ 'product-sr': isSearchResult }">
            <div class="product-image-wrapper" :class="{ 'product-image-wrapper-sr': isSearchResult }">
                <ChippedContent
                    v-if="!isSearchResult"
                    :is-used="isProductUsed(product)"
                    :show-chip="!isRental"
                    :show-discounted-chip="currentRegionId != null && postalCode != null"
                    :is-discounted="isCurrentVariantDiscounted"
                    :discount-reference="currentVariant?.discount_reference ?? null"
                >
                    <template #content>
                        <ProductImage
                            :url="product.thumbnail ?? undefined"
                            :alt="product.title ?? ''"
                            class="product-image"
                        />
                    </template>
                </ChippedContent>
                <ProductImage
                    v-else
                    :url="product.thumbnail ?? undefined"
                    :alt="product.title ?? ''"
                    class="product-image product-image-sr"
                />
            </div>
            <div class="product-content" :class="{ 'product-content-sr': isSearchResult }">
                <h2 class="text-xl-bold-line-height-auto">{{ product.title }}</h2>
                <span v-if="isSearchResult" class="text-md-regular-line-height-auto color-secondary">{{
                    `${t('PRODUCT.SEARCH.ARTICLE_ID')}: ${product.id}`
                }}</span>
                <div class="product-info">
                    <DepotLocationWithIcon
                        v-if="!isRental && currentDepotType != null"
                        :depot-type="currentDepotType"
                        :depot-location="currentDepotLocation"
                        :additional-locations="additionalDepotVariants"
                    />
                    <ProductStockQuantityDisplay
                        v-if="!isRental && currentVariant?.depotQuantity.depot.type !== DepotType.ProductionSite"
                        :stock-quantity="currentVariant?.inventory_quantity ?? 0"
                    />
                    <ProductAvailability
                        v-if="!isRental"
                        :product-available="currentAvailability"
                        :completion-date="currentVariantCompletionDate"
                        :on-demand-completion-date="currentVariantOnDemandCompletionDate"
                    />
                </div>
                <div
                    v-if="currentRegionId != null && postalCode != null"
                    class="product-details"
                    :class="{ 'product-details-vl': isSearchResult }"
                >
                    <p class="product-quantity"></p>
                    <p
                        :class="{ 'product-price-content-sr': isSearchResult }"
                        data-testid="product-list-item-product-price"
                    >
                        <template v-if="!isCurrentVariantDiscounted">
                            <span class="product-price">
                                <span v-if="isRental">
                                    <span v-if="!isRentalDurationSet">
                                        {{ t('PRODUCT.PRICE_FROM') }}
                                    </span>
                                    {{ t('PRODUCT.PRICE_DAILY', { price: formattedProductPrice }) }}
                                </span>
                                <span v-else>
                                    {{ formattedProductPrice }}
                                </span>
                            </span>
                        </template>
                        <template v-else>
                            <div class="product-price-discounted color-discount">
                                <template v-if="isRental">
                                    <span class="product-price-original">
                                        <span v-if="!isRentalDurationSet">
                                            {{ t('PRODUCT.PRICE_FROM') }}
                                        </span>
                                        {{ t('PRODUCT.PRICE_DAILY', { price: formattedOriginalProductPrice }) }}
                                    </span>

                                    <span v-if="!isRentalDurationSet">
                                        {{ t('PRODUCT.PRICE_FROM') }}
                                    </span>
                                    {{ t('PRODUCT.PRICE_DAILY', { price: formattedProductPrice }) }}
                                </template>
                                <template v-else>
                                    <span class="product-price-original">
                                        {{ formattedOriginalProductPrice }}
                                    </span>

                                    {{ formattedProductPrice }}
                                </template>
                            </div>
                        </template>
                        <template v-if="currentDepotType != null && !isRental">
                            <PriceInformation
                                class="price-information-details"
                                :depot-type="currentDepotType"
                                :depot-location="currentDepotLocation"
                            />
                        </template>
                        <span class="product-tax">{{ t('PRODUCT.EXCLUSIVE_TAX') }}</span>
                    </p>
                </div>
                <template
                    v-if="currentRegionId != null && postalCode != null && minProductPrice > 0 && !isSearchResult"
                >
                    <ProductCartButtonGroup
                        :variant-id="currentVariant?.id"
                        :is-add-to-cart-disabled="
                            currentAvailability === Availability.NotAvailable || !isVariantAvailableForCart
                        "
                        @add-to-cart="
                            emits('addToCart', product, currentVariant?.id ?? variantIfNothingIsAvailable?.id ?? '')
                        "
                    />
                </template>
                <Button
                    v-if="(currentRegionId == null || postalCode == null) && !isSearchResult"
                    :label="t('PRODUCT.SHOW_PRICE_BUTTON')"
                    class="cta-button product-list-item-show-price-button"
                    data-testid="product-list-item-show-price"
                    severity="secondary"
                    :dt="buttonFontWeight500"
                    @click.prevent="$emit('showPrice')"
                />
            </div>
        </div>
    </RouterLink>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .link-reset {
        all: unset; // reset link styles
    }

    .grid-item {
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .product {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid main.$color-surface-200;
        overflow: hidden;
        min-width: 100%;
    }

    .product-info {
        margin-top: main.$spacing-4;
        display: flex;
        flex-direction: column;
        gap: main.$spacing-1;
    }

    .product-sr {
        border: 0;
        gap: main.$spacing-4;
    }

    @include main.for-breakpoint-md {
        .product {
            max-width: min(300px, 100%);
            min-width: min(300px, 100%);
        }

        .product-sr {
            min-width: 100%;
            max-width: 100%;
        }
    }

    @include main.for-breakpoint-lg {
        .product {
            max-width: min(282px, 100%);
            min-width: min(282px, 100%);
        }
    }

    .product-content {
        padding: main.$spacing-5;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }

    .product-content-sr {
        padding: 0;
    }

    .product-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .product-price {
        margin-top: main.$spacing-4;
        display: block;
        text-align: right;
        font-size: main.$font-size-4;
        font-weight: 700;
    }

    .product-price-discounted {
        margin-top: main.$spacing-4;
        display: block;
        text-align: right;
        font-size: main.$font-size-4;
        font-weight: 700;
    }

    .product-price-original {
        font-size: main.$font-size-2;
        display: block;
        font-weight: 400;
        text-decoration: line-through;
        color: main.$color-text;
    }

    .product-price-content-sr {
        margin: 0;
    }

    .price-information-details {
        text-align: right;
        font-size: main.$font-size-2;
    }

    .product-tax {
        display: block;
        text-align: right;
        font-size: main.$font-size-2;
        color: main.$color-secondary;
    }

    .depot-city {
        display: flex;
        gap: main.$spacing-2;
        align-items: center;
    }

    .product-availability {
        margin-top: main.$spacing-1;
        flex-grow: 1;
    }

    .product-list-item-show-price-button {
        margin-top: main.$spacing-5;
    }

    .product-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-height: 200px;
    }

    .product-image-wrapper-sr {
        max-height: 150px;
        width: auto;
    }

    .chippedContent {
        width: 100%;
    }

    .product-image {
        width: 100%;
        height: 200px;
        border-radius: 4px 4px 0 0;
        padding-top: main.$spacing-3;
    }

    .product-image-sr {
        height: 150px;
    }

    .cta-button {
        display: flex;
        align-items: baseline;
        width: 100%;
        font-size: 14px;
    }
</style>
