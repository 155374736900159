type FacetsLookup = Record<string, Record<string, number>> | null;

export type FilterQuery = Record<string, string[]>;
export type PreFilteredFacetsQuery = Record<string, string[] | string>;

/**
 * Returns true, if facetKey with facetValue exists in availableFacets and therefore is a valid facet
 * @param availableFacets
 * @param facetKey
 * @param facetValue
 */
export function isValidFacetValue(availableFacets: FacetsLookup, facetKey: string, facetValue: string): boolean {
    return availableFacets?.[facetKey]?.[facetValue] != null;
}

/**
 * Returns a string array of valid and available facet key-value pairs
 * @param facets
 * @param availableFacets
 */
export function getPreFilteredFacets(facets: PreFilteredFacetsQuery, availableFacets: FacetsLookup): string[] {
    const resultArray: string[] = [];
    if (facets != null) {
        for (const key in facets) {
            if (Object.prototype.hasOwnProperty.call(facets, key)) {
                const value = facets[key];
                const values = Array.isArray(value) ? value : [value];
                values
                    .filter((item) => item != null)
                    .forEach((itemValue) => {
                        if (isValidFacetValue(availableFacets, key, itemValue)) {
                            resultArray.push(`${key}:${itemValue}`);
                        }
                    });
            }
        }
    }

    return resultArray;
}

/**
 * Transforms facets so they can be used for URL query
 * @param facets facets that shall be converted to a URL query
 * @param excludedFacets facets that should not be part of the URL query
 */
export function transformFacetsToUrlQuery(facets: string[], excludedFacets: string[]): FilterQuery {
    return facets
        .filter((item) => !excludedFacets.includes(item))
        .reduce<FilterQuery>((acc, curr) => {
            const [facetName, facetValue] = curr.split(':');

            if (facetName == null || facetValue == null) {
                return acc;
            }

            if (acc[facetName] == null) {
                acc[facetName] = [facetValue];
            } else {
                acc[facetName].push(facetValue);
            }

            return acc;
        }, {});
}
